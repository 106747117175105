<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <CCol cols="6">
          <h1 class="mr-sm-4">ADMIN</h1>
        </CCol>

        <CCol cols="6" class="text-right">
          <router-link to="/admin/details/0">
            <button type="button" class="btn btn-main-2 button">CREATE NEW</button>
          </router-link>
        </CCol>
      </CRow>
      <div class="bg-white-border px-4 px-sm-5 py-4 mt-4">
        <b-row class="no-gutters justify-content-between">
          <b-col md="5" class="px-0 px-lg-3 py-3 px-2 pt-lg-2">
            <b-input-group class="panel-input-serach">
              <b-form-input
                class="input-serach"
                placeholder="First Name, Last Name, User Name"
                v-model="filter.search"
                @keyup="handleSearch"
              ></b-form-input>
              <b-input-group-prepend @click="btnSearch">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4">
            <b-table
              responsive
              class="text-center"
              striped
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(name)="data">
                <div v-if="data.item.name == null">- -</div>
                <div v-else>{{data.item.name}}</div>
              </template>
              <template v-slot:cell(email)="data">
                <div v-if="data.item.email == null">-</div>
                <div v-else>{{data.item.email}}</div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <b-button variant="link" class="px-1 py-0">
                  <router-link :to="'/admin/details/'+ data.item.userId">
                    <font-awesome-icon icon="pencil-alt" class="text-warning" title="View" />
                  </router-link>
                </b-button>
                  <b-button variant="link" class="px-1 py-0">
                  <font-awesome-icon
                    icon="trash-alt"
                    class="text-danger"
                    @click="openModalDelete(data.item)"
                  />
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="form-inline justify-content-center justify-content-md-between">
            <b-row>
              <b-col>
                <div class="d-inline-block layout-paging">
                  <b-pagination
                    v-model="filter.page"
                    :total-rows="rows"
                    :per-page="filter.pageSize"
                    class="m-md-0"
                    @change="pagination"
                    align="center"
                  ></b-pagination>
                </div>
                <div class="d-inline-block ml-md-3 layout-paging">
                  <p class="total-record-paging text-nowrap text-center">{{totalRowMessage}}</p>
                </div>
              </b-col>
            </b-row>
            <b-form-select
              v-model="filter.pageSize"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- Modal -->
    <ModalAlertConfirm
      msg="ยืนยันการลบ"
      :text="modalMessage"
      btnConfirm="Delete"
      colorBtnConfirm="danger"
      btnCancel="Close"
      ref="isModalAlertConfirm"
      @confirm="btnDelete"
    />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>
<script>
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
export default {
  name: "admin",
  components: {
    ModalAlertConfirm,
    ModalAlert,
    ModalAlertError,
  },
  data() {
    return {
      statusList: [],
      modalMessage: "",
      requestDeleteUser: {
        userId: null,
      },
      fields: [
        {
          key: "firstName",
          label: "First Name",
          class: "w-100px text-nowrap",
        },
        {
          key: "lastName",
          label: "Last Name",
          class: "w-100px text-nowrap",
        },
        {
          key: "userName",
          label: "User Name",
          class: "w-100px text-nowrap",
        },
        { key: "action", label: "Action", class: "w-100px text-nowrap" },
      ],
      items: [],
      isBusy: true,
      rows: 0,
      filter: {
        search: "",
        status: [],
        page: 1,
        pageSize: 10,
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      totalRowMessage: "",
    };
  },
  created: async function () {
    this.$isLoading = true;
    await this.getList();
    this.$isLoading = false;
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/admin/list`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.data;
        this.rows = resData.detail.totalData;
        if (resData.detail.totalData > 0) {
          let numRecordFirst = this.filter.page * this.filter.pageSize;
          numRecordFirst = numRecordFirst - this.filter.pageSize + 1;
          let numRecordLast =
            this.filter.page * this.filter.pageSize > resData.detail.totalData
              ? resData.detail.totalData
              : this.filter.page * this.filter.pageSize;
          this.totalRowMessage =
            "Showing " +
            numRecordFirst +
            " - " +
            numRecordLast +
            " of " +
            resData.detail.totalData +
            " entries";
        }
        
      }

      this.isBusy = false;
    },
    pagination(Page) {
      this.filter.page = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.pageSize = value;
      this.getList();
    },
    handleSearch(e) {
      if (e.keyCode === 13) {
        this.filter.page = 1;
        this.getList();
      }
    },
    btnSearch() {
      this.filter.page = 1;
      this.getList();
    },
    openModalDelete(value) {
      this.requestDeleteUser.userId = value.userId;
      this.modalMessage = "คุณต้องการลบ " + value.firstName + " " + value.lastName + " ใช่หรือไม่?";
      this.$refs.isModalAlertConfirm.show();
    },
    btnDelete: async function () {
      this.$refs.isModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/admin/remove`,
        null,
        this.$headers,
        this.requestDeleteUser
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        this.filter.page = 1;
        await this.getList();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>
